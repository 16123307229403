import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery, Link } from "gatsby";

class LatestStories extends Component {
    render() {
        const { data } = this.props;
        const { edges: posts } = data.allMarkdownRemark;

        return (
          <div className="lateststories">
            <h3>Laatste artikelen</h3>
            <ul>
              {posts &&
                posts.map(({ node: post }) => (
                  <li key={ post.id }>
                    <Link to={post.fields.slug}> {post.frontmatter.title} </Link>
                  </li>
                ))}
            </ul>
          </div>
        );
    }
}

LatestStories.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

const queryLatestStories = () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          limit: 3
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
              }
              id
              excerpt(pruneLength: 125)
            }
          }
        }
      }
    `}
    render={(data, count) => <LatestStories data={data} count={count} />}
  ></StaticQuery>
);

export default queryLatestStories