import React from "react";
import Layout from "../components/page-objects/Layout";
import Header from "../components/page-objects/Header";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { graphql, Link } from "gatsby";
import Spacer from "../components/ui-objects/Spacer";
import LatestStories from "../components/page-objects/LatestStories";
import { Helmet } from "react-helmet";

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout 
      // helmet seo content
      description={post.frontmatter.seoinfo.description}
      title={post.frontmatter.seoinfo.seotitle}
      keywords={post.frontmatter.seoinfo.keywords}
    >
      <Header
        headerImage={post.frontmatter.headerimagewrap.featuredimage.childImageSharp.fixed.srcWebp}
        headerImageAlttext={post.frontmatter.headerimagewrap.alttextheaderimage}
        promoblockVisible={post.frontmatter.headerpromo.headerpromovisible}
        promoblockContent={post.frontmatter.headerpromo.headerpromocontent}
        promoblockButtonVisible={post.frontmatter.headerpromo.headerpromobuttonvisible}
        promoblockButtonText={post.frontmatter.headerpromo.headerpromobuttonlabel}
        promoblockButtonUrl={post.frontmatter.headerpromo.headerpromobuttonurl}
        backgroundPositie={post.frontmatter.headerpositie}
        fullBackgroundImage={post.frontmatter.headerimagewrap.fullbgimage}
      />
      <Helmet>
        <title>{post.frontmatter.title}</title>
      </Helmet>
      <section>
        <section className="blogcontent">
          <div className="article">
            <Spacer size="xxl" />
            <h1>{post.frontmatter.title}</h1>
            <Spacer size="l"/>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            {post.frontmatter.tags && post.frontmatter.tags.length ? (
              <div style={{ marginTop: `4rem` }} className="tags-article">
                <h4>Tags</h4>
                <ul>
                  {post.frontmatter.tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
          <div className="latest-stories">
            <Spacer size="xl" />
            <LatestStories />
            <Spacer size="xl" />
          </div>
        </section>
      </section>
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        headerimagewrap {
          featuredimage {
            childImageSharp {
              fixed(webpQuality: 90, width: 1600) {
                  srcWebp
              }
            }
          }
          alttextheaderimage
          fullbgimage
        }
        headerpromo {
          headerpromobuttonlabel
          headerpromobuttonurl
          headerpromobuttonvisible
          headerpromocontent
          headerpromovisible
        }
        headerpositie
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        seoinfo {
          description
          seotitle
          keywords
        }
      }
    }
  }
`;
